@import url('https://fonts.googleapis.com/css?family=Roboto:400,700|Roboto+Slab:400,700');
@import '~font-awesome/scss/font-awesome';
@import '~font-awesome-animation/font-awesome-animation';
body, html {
    background-color: #000;
    color: #fff;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
}
canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}
.main {
    display: flex;
    flex-direction: column;
    pointer-events: none;
    color: #fff;
    z-index: 1;
    position: relative;
    max-width: 100%;
    filter:Alpha(opacity=0);
    h1 {
        font-family: 'Roboto Slab', serif;
        font-size: 3em;
        text-align: center;
    }
    h2 {
        text-align: center;
        font-weight: normal;
    }
    ul {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        list-style: none;
        padding: 0;
        li {
            a {
                pointer-events: auto;
                i {
                    color: #fff;
                    transition: all 0.3s;
                }
            }
        }
    }
}